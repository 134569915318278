import { Card } from 'primereact/card';
import { Avatar } from 'primereact/avatar';

export const Steps = () => {
  const StepsInfo = [
    {
      number: '1',
      title: 'Elegí el plan que se adapte a tus necesidades',
      icon: 'pi-check-circle',
    },
    {
      number: '2',
      title: 'Damos de alta tu cuenta y obtenés acceso a la plataforma',
      icon: 'pi-user-plus',
    },
    {
      number: '3',
      title: 'Ya en línea, integramos tu número de WhatsApp a Pip!',
      icon: 'pi-window-minimize',
    },
    {
      number: '4',
      title:
        'Capacitamos al equipo para que aprovechen al máximo sus funcionalidades',
      icon: 'pi-check-square',
    },
  ];

  const secondStepsInfo = [
    {
      title: () => (
        <>
          Prioridad de <span>soporte vía WhatsApp</span>
        </>
      ),
      icon: 'pi-exclamation-circle',
      className: 'col-6 md:col-4 mt-2',
    },
    {
      title: () => (
        <>
          <span>100% autogestión</span> de funcionalidades
        </>
      ),
      icon: 'pi-cog',
      className: 'col-6 md:col-4 mt-2',
    },
    {
      title: () => (
        <>
          En línea: <span>instructivos de funcionamiento</span>
        </>
      ),
      icon: 'pi-file',
      className: 'col-12 md:col-4 mb-2',
    },
  ];

  const avatar = (icon) => (
    <span className="font-blue flex justify-content-center mb-3">
      <Avatar
        className={`pi ${icon} flex`}
        shape="circle"
        style={{
          width: '4.5rem',
          height: '4.5rem',
          borderRadius: '128px',
          fontSize: '3rem',
          backgroundColor: '#EDF1EE !important',
          color: '#19418C',
          fontWeight: '900',
        }}
      />
    </span>
  );

  return (
    <>
      <div className="text-center mt-7 steps-container overflow-hidden">
        <div className="mb-3 md:mb-5 md:ml-8 text-2xl md:text-5xl p-4 text-left flex flex-column">
          <span className="font-w-900 font-turquoise font-bold">
            IMPLEMENTACIÓN
          </span>
          <span className="font-blue">FÁCIL, INMEDIATA Y ASISTIDA</span>
        </div>
          <div className="grid ml-3 md:ml-8 md:flex md:flex-column">
            {StepsInfo.map((step, index) => (
              <div
                key={index}
                className="col-12 mb-4 flex align-items-center"
              >
                <i className={`pi ${step.icon} text-4xl font-turquoise`}></i>
                <div className="text-base md:text-2xl text-left pl-4 pr-4">
                  <span className="font-w-900">{step.number}. </span>
                  {step.title}
                </div>
              </div>
            ))}
          </div>
          <div className="grid mt-6 md:mt-8 steps-turquoise second-steps">
          <div className="flex bonus-track-container bonus-desktop">
                <div className="bonus-track">Bonus track</div>
            </div>
            <div className="flex flex-wrap">
              <div className="flex bonus-track-container bonus-mobile">
                <div className="bonus-track">Bonus track</div>
              </div>
              {secondStepsInfo.map((step, index) => (
                <div key={index} className={`${step.className} `}>
                  <Card
                    title={step.title()}
                    header={avatar(step.icon)}
                    className="col-12 md:col-12 text-0 shadow-none text-left md:text-center p-1 md:p-4 md:pt-0 mt-4 md:mt-0"
                    style={{ backgroundColor: 'transparent' }}
                  ></Card>
                </div>
              ))}
            </div>
          </div>
      </div>
    </>
  );
};