const images = require.context('../assets/img/companies', true, /\.png$/);
const imageKeys = images.keys();

function extractNumberFromFilename(filename) {
  const match = filename.match(/(\d+)\.png$/);
  return match ? parseInt(match[1], 10) : -1;
}

const sortedImageKeys = imageKeys.sort((a, b) => {
  const numberA = extractNumberFromFilename(a);
  const numberB = extractNumberFromFilename(b);
  return numberA - numberB;
});

const imageList = sortedImageKeys.map((key) => images(key));

export const Companies = () => {
  const ImageComponent = () => {
    return imageList.map((image, index) => (
      <div key={index} className="col-6 md:col-2 flex justify-content-center">
        <img src={image} alt={`Company ${index + 1}`} className=" w-8rem" />
      </div>
    ));
  };

  return (
    <div className="text-center mt-7 companies-container overflow-hidden">
      <div className="mb-3 md:mb-2 text-2xl md:text-5xl md:ml-8 p-4 text-left flex flex-column">
        <span className="font-blue md:mb-2"> EMPRESAS QUE</span>
        <span className="font-w-900 font-turquoise font-bold">
          CONFÍAN EN NOSOTROS
        </span>
      </div>
      <div className="grid p-4 md:p-8">
        <ImageComponent />
      </div>
    </div>
  );
};