import './App.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeflex/primeflex.css";
import 'primeicons/primeicons.css';
import { Banner } from './components/Banner';
import { Info } from './components/Info';
import { Advantages } from './components/Advantages';
import { Tools } from './components/Tools';
import { Steps } from './components/Steps';
import { Companies } from './components/Companies';
import { Footer } from './components/Footer';


function App() {
    return (
        <div className="App">
            <Banner />
            <Info />
            <Advantages />
            <Tools />
            <Steps />
            <Companies />
            <Footer />
        </div>
    );
}

export default App;
