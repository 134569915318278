import laptop from '../assets/img/laptop.png';
import { Card } from 'primereact/card';

export const Info = () => {
  return (
    <>
      <div className="w-full relative overflow-hidden info-container">
        <Card className="absolute info-card flex justify-content-center text-center border-round-2xl z-5 w-8 md:w-7 top-50">
          <p className="mt-0 mb-0 text-sm md:text-2xl md:text-left">
            La herramienta que <span>potencia </span>y <span>optimiza</span> tu
            gestión en <span>WhatsApp</span>
          </p>
        </Card>
        <div className="absolute info-description md:w-7 md:text-xl">
          <p>Para comunicarse con empresas y contratar productos o servicios.</p>
          <p>La migración de gestión de clientes a WhatsApp es un hecho inevitable.</p>
          <p className="font-w-900">Todas las empresas deben tener este canal de comunicación activo.</p>
        </div>
        <img src={laptop} alt="computer" className="w-7 md:w-6 relative z-2" />
      </div>
    </>
  );
};