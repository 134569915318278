import logo from '../assets/img/pip-logo.png'

import meta from '../assets/img/meta.png'
import cace from '../assets/img/cace.png'

export const Banner = () => {

    return (
        <>
            <div className="grid grid-nogutter mt-4 banner-container">
                <div className="col-12 mt-4 md:mt-5 flex align-items-center justify-content-center partner-images">
                    <div className="col-3 flex justify-content-center">
                        <img src={meta} alt="logo-meta" />
                    </div>
                    <div className="col-3 flex justify-content-center">
                        <img src={cace} alt="logo-cace" />
                    </div>
                </div>
                <div className="col-12 p-4 flex align-items-center justify-content-center flex-column text-xl md:text-5xl">
                    <img className="banner-logo mb-3 md:w-4" src={logo} alt="logo-pip" />
                    <span className="font-blue">
                        LA SOLUCIÓN DIGITAL
                    </span>
                    <span className="font-blue">
                        QUE TU EMPRESA NECESITA
                    </span>
                </div>
            </div>
        </>
    )
}