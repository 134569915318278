export const toolsInfo = [
    {
        title: 'Bot Multinivel',
        description: [
            'Asigna cada chat al área correspondiente.',
            'Automatiza el flujo de trabajo.',
            'Optimiza el tiempo de tus asesores.',
            'Mejora la experiencia de atención.'
        ],
        icon: 'pi-sitemap'
    },
    {
        title: 'Recordatorios',
        description: [
            'Nunca olvides nada de tu agenda.',
            'Mejora la productividad del equipo.',
            'Te diferencia de la competencia.',
            'Evita mal entendidos o mala comunicación.'
        ],
        icon: 'pi-bell'
    },
    {
        title: 'Multioperadores',
        description: [
            'Todo tu equipo puede trabajar en simultáneo, con un mismo número de teléfono.',
            'Asigna comunicaciones por operador y monitorea la gestión de cada uno de ellos.'
        ],
        icon: 'pi-users'
    },
    {
        title: 'Programar mensajes',
        description: [
            'Deja planificados los mensajes que quieres enviar a tus contactos.',
            'Envía recordatorios para eventos futuros, sin tener que anotarlos en una agenda.'
        ],
        icon: 'pi-calendar'
    }

]

export const advantagesInfo = [
    {
        title: 'INCREMENTA ',
        subtitle: 'TU CAUDAL DE VENTAS',
        description: 'Optimiza el tiempo de espera de tus clientes, organizando de manera más efectiva el trabajo y mejorando la conversión.',
        icon: 'pi-chart-line'
    },
    {
        title: 'REDUCE',
        subtitle: 'COSTOS Y ENERGÍA',
        description: 'Mantén el control de la gestión comercial en tiempo real, utilizando reportes para medir el desempeño de tu equipo y monitorear el desempeño por operador.',
        icon: 'pi-clock'
    },
    {
        title: 'MEJORA',
        subtitle: 'EXPERIENCIA DEL USUARIO',
        description: 'Agiliza el proceso de comunicación aumentando la velocidad, calidad y precisión en las respuestas. Resuelve consultas automáticamente.',
        icon: 'pi-comment'
    },
    {
        title: 'EXPANDE',
        subtitle: 'LA COMUNICACIÓN MULTICANAL',
        description: 'Llega a tus clientes de forma masiva y automática vía Whatsapp, sms, correo electrónico y llamadas de voz, con notificaciones programadas.',
        icon: 'pi-volume-up'
    }
];
