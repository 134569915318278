import logo from '../assets/img/pip-logo.png';

export const Footer = () => {
  return (
    <>
          <footer className="mt-4 mb-8 grid grid-nogutter flex flex-column align-items-center justify-content-center overflow-hidden">
            <div className="col-12 flex flex-column align-items-center justify-content-center">
              <img src={logo} alt="logo-pip" className="w-10rem md:w-18rem mb-3 md:mb-5 overflow-hidden" />
              <a
                href="https://soypip.com/wpp-ar"
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline"
              >
                <p className="mt-0 line-height-2 flex align-items-center font-bold text-sm md:text-2xl">
                  <i className="pi pi-whatsapp mr-2 font-blue text-xl md:text-4xl"></i>
                  CONTACTA A NUESTRO EQUIPO
                </p>
              </a>
            </div>
            <div className='md:col-12 md:flex md:flex-row'>
              <div className='md:col-4 md:col-offset-2 md:flex md:justify-content-center'>
              <p className=" mt-0 mb-3 line-height-2 flex align-items-center font-bold text-sm md:text-2xl">
                <i className="pi pi-globe mr-2 font-blue text-xl md:text-4xl"></i>
                  soypip.com
              </p>
              </div>
              <div className='md:col-4 md:flex md:justify-content-center'>
              <a href="mailto:contacto@soypip.com" className="no-underline">
                <p className="mt-0 mb-3 line-height-2 flex align-items-center font-bold text-sm md:text-2xl">
                  <i className="pi pi-envelope mr-2 font-blue font-bold text-xl md:text-4xl"></i>
                  contacto@soypip.com
                </p>
              </a>
              </div>
              </div>
          </footer>
    </>
  );
};