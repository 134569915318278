import { Card } from 'primereact/card';
import { advantagesInfo } from './utils.js/data';
import logo from '../assets/img/pip-logo.png';
import { Avatar } from 'primereact/avatar';
import { Carousel } from 'primereact/carousel';
export const Advantages = () => {
  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '600px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '480px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const advantageTemplate = (advantage) => {
    return (
      <div className="col-12 md:col-3 mb-4">
        <span className="flex justify-content-center mb-3">
          <Avatar
            className={`pi ${advantage.icon} flex align-items-center justify-content-center text-4xl`}
            size="xlarge"
            shape="circle"
            style={{
              backgroundColor: 'transparent',
              borderRadius: '128px',
              border: '2px solid #1ED2AF',
              color: '#1ED2AF',
              boxShadow:
                '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.08)',
            }}
          />
        </span>
        <Card
          title={advantage.title}
          subTitle={advantage.subtitle}
          className="col-12 md:col-3 md:col-12 flex align-self-stretch border-round-2xl"
        >
          <p className="m-0 font-neutral">{advantage.description}</p>
        </Card>
      </div>
    );
  };
  return (
    <>
      <div className="text-center mt-4 md:mt-0 advantages-container overflow-hidden">
        <div className="col-12 p-4 flex align-items-center justify-content-center flex-column pb-0 mb-7">
          <img className="w-12rem md:w-22rem" src={logo} alt="logo-pip" />
          <span className="text-sm md:text-xl font-blue">
            aporta a tu empresa{' '}
          </span>
        </div>
        <div className="grid carousel-desktop md:ml-6 md:mr-6">
          <div className="flex align-items-stretch">
            {advantagesInfo.map((advantage, index) => (
              <div key={index} className="col-12 md:col-3 mb-4">
                <span className="flex justify-content-center mb-3 px-5">
                  <Avatar
                    className={`pi ${advantage.icon} flex align-items-center justify-content-center text-4xl`}
                    size="xlarge"
                    shape="circle"
                    style={{
                      backgroundColor: 'transparent',
                      borderRadius: '128px',
                      border: '2px solid #1ED2AF',
                      color: '#1ED2AF',
                      boxShadow:
                        '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.08)',
                    }}
                  />
                </span>
                <Card
                  title={advantage.title}
                  subTitle={advantage.subtitle}
                  className="col-12 md:col-3 md:col-12 flex align-self-stretch border-round-2xl"
                >
                  <p className="m-0 font-neutral">{advantage.description}</p>
                </Card>
              </div>
            ))}
          </div>
        </div>
        <div className="carousel-mobile ">
          <Carousel
            value={advantagesInfo}
            itemTemplate={advantageTemplate}
            responsiveOptions={responsiveOptions}
            numVisible={1}
            numScroll={1}
            circular
            autoplayInterval={3000}
          />
        </div>
      </div>
    </>
  );
};