import { Card } from 'primereact/card';
import computer from '../assets/img/computer.png';
import { toolsInfo } from './utils.js/data';
import { Avatar } from 'primereact/avatar';
import { useRef } from 'react';
import { Carousel } from 'primereact/carousel';

export const Tools = () => {
  const cardRefs = useRef([]);

  const avatar = (icon) => (
    <span className="flex justify-content-center mb-3">
      <Avatar
        className={`pi ${icon} flex align-items-center justify-content-center text-4xl`}
        size="xlarge"
        shape="circle"
        style={{
          backgroundColor: '#ffffff',
          borderRadius: '128px',
          border: '2px solid #1ED2AF',
          color: '#1ED2AF',
          boxShadow:
            '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.08)',
        }}
      />
    </span>
  );
  const toolTemplate = (tool) => {
    return (
      <Card
        title={tool.title}
        header={avatar(tool.icon)}
        className="col-12 md:col-3 md:col-12 text-0 p-10 border-round-3xl"
        style={{ backgroundColor: 'transparent' }}
      >
        <ul className="m-0 text-left pl-4 mb-5">
          {tool.description.map((desc, idx) => (
            <li key={idx} className="font-xs">
              {desc}
            </li>
          ))}
        </ul>
      </Card>
    );
  };
  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '600px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '480px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  return (
    <>
      <div className="tools-container">
        <div className="grid grid-nogutter mt-6">
          <div className="col-6 md:col-5 md:col-offset-1 flex flex-column font-blue pl-3 md:p-15">
            <div className="font-bold h-7rem md:h-22rem">
              <p className="mb-0 text-4xl md:text-8xl font-blue">4</p>
              <p className="text-1xl md:text-6xl m-0 font-blue">HERRAMIENTAS</p>
              <p className="text-1xl md:text-6xl m-0 font-blue">CLAVES</p>
            </div>
            <div>
              <p className="mb-0 text-sm md:text-4xl md:mt-15">
                Que <span className="font-bold">no encuentras</span> en{' '}
                <span className="font-bold">WhatsApp</span> tradicional
              </p>
            </div>
          </div>
          <div className="col-6 md:col-6 p-0 flex align-items-center overflow-hidden tools-img-computer md:justify-content-end">
            <img
              src={computer}
              alt="computer"
              className="sm:hidden md:flex h-15rem lg:h-13rem xl:h-15rem "
            />
          </div>
        </div>
        <div className="text-center mt-7 tools-cards-container">
          <div className="grid grid-nogutter carousel-desktop">
            <div className="flex flex-wrap">
              {toolsInfo.map((tool, index) => (
                <div
                  key={index}
                  className="col-10 col-offset-1 md:col-3 md:col-offset-0 mb-4 px-5 flex align-items-stretch border-round-3xl"
                >
                  <Card
                    ref={(el) => (cardRefs.current[index] = el)}
                    title={tool.title}
                    header={avatar(tool.icon)}
                    className="col-12 md:col-12 text-0 p-5 md:p-1"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <ul className="m-0 text-left pl-4 mb-5">
                      {tool.description.map((desc, idx) => (
                        <li key={idx} className="font-xs">
                          {desc}
                        </li>
                      ))}
                    </ul>
                  </Card>
                </div>
              ))}
            </div>
          </div>
          <div className="carousel-mobile">
            <Carousel
              value={toolsInfo}
              itemTemplate={toolTemplate}
              responsiveOptions={responsiveOptions}
              numVisible={1}
              numScroll={1}
              circular
              autoplayInterval={3000}
            />
          </div>
        </div>
      </div>
    </>
  );
};